<template>
  <div class="mt-16">
    <el-card shadow="never">
      <el-button type="primary" @click="dialogVisible = true"
        >添加分类</el-button
      >
      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <!-- <el-table-column align="center" type="index" label="序号" /> -->
          <el-table-column prop="userName" align="center" label="编号" />
          <el-table-column prop="userName" align="center" label="分类名称" />
          <el-table-column prop="userName" align="center" label="数量" />
          <el-table-column prop="userName" align="center" label="排序" />
          <el-table-column prop="userName" align="center" label="操作" />
        </template>
      </commonTable>
    </el-card>
    <el-dialog
      title="添加分类"
      :visible.sync="dialogVisible"
      width="50%"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="78px"
      >
        <el-form-item label="分类名称" prop="title">
          <el-input
            v-model="ruleForm.options"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
export default {
  components: {
    commonTable,
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        name: "",
        options: "",
        coverImg: "",
      },
      rules: {},
      options: [
        {
          value: "选项1",
          label: "自定义媒体页",
        },
        {
          value: "选项2",
          label: "短视频",
        },
      ],
      value: "",
      tableData: [],
      pageSize: "",
      currentPage: "",
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
  },
};
</script>

<style>
</style>